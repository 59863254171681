import React from 'react';
import { BrowserRouter as Router, Redirect } from 'react-router-dom'

class App extends React.Component {
  constructor(props) {
    super(props);
    this.redirect = false
    if (window.location.pathname != "") {
      this.redirect = true
    }
  }

  renderRedirect = () => {
    if (this.redirect) {
      return <Router><Redirect to=""/></Router>
    }
  }

  render() {
    return (
      <div id="content">
        {this.renderRedirect()}
        <center>
          <img class="logo" src="/img/logo.png"/><br/>
          <div id="headlines">
            <p>Particulares</p> | <p>Empresas</p> | <p>Vacacional</p> | <p>Hosteler&iacute;a</p>
          </div>
          <div id="information">
            <div id="description">
              Adiós a los productos químicos en el hogar y en el medio ambiente.<br/><br/>

              Tecnología moderna, inagotable y limpia, es la base del éxito del nuevo Ecomodo. Gracias a este novedoso método de lavado, se cambia la manera de pensar en la limpieza, se cambia de actitud, la forma de comprar e incluso la acción.<br/><br/>

              Hoy en día, todos buscamos las soluciones más sostenibles, ecológicas y limpias para nuestro día a día. Apostamos por productos, servicios y mejoras que no impliquen productos químicos ni que sean nocivos para nosotros o el medio ambiente.<br/><br/>

              Ahora, la revolución ha llegado a la limpieza de la ropa con Ecomodo. ¡Descúbrelo cuanto antes y déjate seducir por sus múltiples ventajas!<br/><br/>
            </div>
            <div id ="contact">
              <p>
                Contacto
              </p><br/>
              Toñi Zamora<br/>
              679 946 414<br/>
              limpiezastoni7@gmail.com<br/>
            </div>
            07530 · Mallorca<br/>
          </div>
        </center>
      </div>
    );
  }
}

export default App;
